import React, { useState, useEffect } from 'react';
import { ReactComponent as Icon1 } from './pics/buyme.svg';
import { ReactComponent as Icon2 } from './pics/buynow.svg';
import { ReactComponent as Icon3 } from './pics/forsale.svg';
import { ReactComponent as Icon4 } from './pics/instore.svg';
import { ReactComponent as Icon5 } from './pics/letsshop.svg';

export default function RandomBuyIcon(props) {
  const [randomIcon, setRandomIcon] = useState(0);

  useEffect(() => {
    const icons = [<Icon1 />, <Icon2 />, <Icon3 />, <Icon4 />, <Icon5 />];
    const min = 0;
    const max = icons.length - 1;
    const rand = Math.round(min + Math.random() * (max - min));
    setRandomIcon(icons[rand]);

  }, []);

  return randomIcon;
}
