import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import RandomBuyIcon from './components/randomBuyIcon/randomBuyIcon';
import './App.css';

const SearchHolder = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 300px;
  width: 300px;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #000;

  .inputHolder {
    margin-right: auto;
    margin-left: auto;
    max-width: 300px;
    width: 300px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 1px solid black;
    height: 30px;
  }

  .searchInput {
    font-family: "Times New Roman", Times, serif !important;
    font-size: 12px;
    line-height: 12px;
    text-transform: none;
    width: 260px;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
    border-radius: 0;
    border: none;

    & :before, :after {
      box-sizing: content-box;
    }
  }

  .eyeButton {
    display: relative;
    height: 30px;
    width: 30px;
    background-color: #000;
    padding:0;
    margin:0;
    overflow: hidden;
    border: 1px solid black;
  }

.eyeIcon {
    width: 30px;
    height: 15px;
    position: absolute;
    top: 8px;
  }
`;

const ProductsDrop = styled.div`
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  max-width: 270px;
  width: 100%;
  text-align: left;
  font-family: "Times New Roman", Times, serif !important;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize;
  position: absolute;
  z-index: 666;
  top: 30px;
  left: 0;
  height: auto;
  max-height: 402px;
  overflow: scroll;
`;

const ProductItem = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  background-color: #fff;

  &:hover {
    background-color: #eee;
  }

  & a {
    padding: 5px;
    width: 75%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & svg {
    position: absolute;
    top: 10px;
    right: 5px;
    height: 7px;
    width: auto;
    fill: #ff003c;w  
  }

  & svg g path {
    fill: #ff003c;
  }
`;

function App() {
  const [query, setQuery] = useState('');
  const [showDropDown, setShowDropDown] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const result = await axios(
        'https://api.omnipollo.com/shopify/?query=' + query
      );
      if (!ignore) {
        setProducts(result.data)
        setShowDropDown(true)
      }
    }

    if (query.length > 1) fetchData();
    else setProducts([]);
    return () => {
      ignore = true;
    };
  }, [query]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropDown(false)
        }
        else {
          setShowDropDown(true)
        }
      }

      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div className="App" ref={wrapperRef}>
      <SearchHolder>
        <div className="inputHolder">
          <input type="text" className="searchInput"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="eyeButton">
          <svg className="eyeIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 15">
              <g
                id="Group_156"
                data-name="Group 156"
                transform="translate(-34.977 -125.356)"
              >
                <path
                  id="Path_71"
                  data-name="Path 71"
                  fill="#fff"
                  d="M35.6,21.147s-6.742,7.247-15,7.247-15-7.247-15-7.247,6.742-7.247,15-7.247S35.6,21.147,35.6,21.147Z"
                  transform="translate(29.377 111.456)"
                ></path>
                <ellipse
                  id="Ellipse_15"
                  data-name="Ellipse 15"
                  fill="#000"
                  cx="6.236"
                  cy="6.573"
                  rx="6.236"
                  ry="6.573"
                  transform="translate(43.742 126.03)"
                ></ellipse>
              </g>
            </svg>
          </div>
        </div>
        {products.length > 0 && showDropDown && (
          <ProductsDrop>
            {products.map((product) => (
              <ProductItem key={`${product.url}-${product.title}`}>
                <a href={product.url}>{product.title}</a>
                {product.availableForSale && <RandomBuyIcon />}
              </ProductItem>
            ))}
          </ProductsDrop>
        )}
      </SearchHolder>
    </div>
  );
}

export default App;
